'use strict';

module.exports = function () {
    var productTileWrapper = $('.widget-product-grid-inner-wrapper .experience-hsngassets-producttile .product');

    productTileWrapper.each(function () {
        var productTile = $(this).children();

        if (productTile.length === 0) {
            $(this).parent().remove();
        }
    });
};
